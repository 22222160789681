$red-gradation: linear-gradient(
  180.81deg,
  #ff7337 0%,
  #ff0b5e 47.92%,
  #ff0b5e 100%
);
$pink-gradation: linear-gradient(180deg, #f60b5c 0%, #fb149e 100%);
$primary: #FF0B5E;
$secondary: #3757ff;
$yellow: #f8b62d;
$orange: #ff9f59;
$success: #00ee98;
$information: #d4ebff;
$warning: #fb27ff;
$danger: #e4002c;

$black: #272934;
$light-black: #3D3E48;
$light-purple: #969FBE;
$gray: #4f5464;
$light-gray: #7C7C80;
$white: #ffff;

$shade: #ebf0f4;
$shade2: #f4f7fb;
$tint: #ebf4f8;
