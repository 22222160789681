@import '/src/styles/variables';
@import '/src/styles/colors';

.bg {
  background: #0f123d;
  min-height: 100vh;
  position: relative;
  @include mq(md) {
    padding: 80px 24px;
  }
  &::after {
    background-color: rgba(24, 27, 69, 0.4);
    border-radius: 60px;
    content: '';
    display: block;
    height: 1220px;
    position: absolute;
    right: -22px;
    top: -866px;
    -webkit-transform: rotate(-52deg);
    -moz-transform: rotate(-52deg);
    -ms-transform: rotate(-52deg);
    transform: rotate(-52deg);
    width: 1082px;
    z-index: 0;
    @include mq(md) {
      content: none;
    }
  }
}

.box {
  background: #fff;
  border-radius: 8px;
  left: 50%;
  margin: 0 auto;
  max-width: 420px;
  padding: 40px 60px 60px;
  position: absolute;
  text-align: center;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  @include mq(md) {
    max-width: inherit;
    padding: 40px 20px;
  }
}

.logo {
  margin: 0 auto;
  width: 240px;
}

.logoText {
  color: $black;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 16px;
}

.button {
  padding: 8px 12px !important;
}

.footer {
  bottom: 40px;
  color: #fff;
  display: flex;
  justify-content: space-between;
  left: 40px;
  position: fixed;
  width: calc(100% - 80px);
}

.link {
  cursor: pointer;
}
